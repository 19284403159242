/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Container, Col, Row } from "reactstrap";

// core components

// assets
import classRoom from 'assets/img/children_classroom.jpeg';
import downloadGoogleApp from "../../assets/img/google-play-badge.png";
import bannerImage from "assets/img/hero-banner.jpg";
// import downloadIosApp from "../../assets/img/download-apple.png";

function IndexHeader() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundImage:
            `url(${bannerImage})`,
        }}
        className="page-header align-items-center"
        data-parallax={false}
        ref={pageHeader}
      >
        {/*<div className="filter" />*/}
        <Container>
          <Row className="align-items-center">
            <Col lg={5} md={6} sm={12} className="mt-md-0 pt-md-0 mt-5 pt-5 ml--5">
              <div className="motto">
                <h1>
                We believe that providing quality education is the best way to pull communities out of poverty, and ignorance.
                </h1>
                {/* <h3>We do whatever it takes to make sure children don’t just survive but thrive. Helper believes that every child should be able to make their mark on their world and help build a better future.
                </h3> */}
                <br />
              </div>
            </Col>
            <Col lg={7} md={6} sm={12} className="floating-hero-image pt-5">
              <img className="img-fluid" id="barner-image" src={classRoom} alt={'class room'}/>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
