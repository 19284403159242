/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Redirect, Switch, withRouter} from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
// pages
import Index from "views/Index.js";
import Privacy from "views/Privacy.js";
// others

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const Scroll = withRouter(ScrollToTop);

ReactDOM.render(
  <BrowserRouter>
    <Scroll>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />}/>
        <Route
          path="/privacy-policy"
          render={(props) => <Privacy {...props} />}
        />
        <Redirect to="/index" />
      </Switch>
    </Scroll>
  </BrowserRouter>,
  document.getElementById("root")
);
