/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import Fade from 'react-reveal/Fade';
import CountUp from 'react-countup';
// import {Link} from "react-router-dom";

import logo from "../assets/img/brand/mudupay-logo.png";
import qrImage from "../assets/img/qr-image.png";
import transferFigure from "../assets/img/transfer-figure.png";
import downloadAppBg from "../assets/img/download-app-bg.png"
import downloadGoogleApp from "../assets/img/google-play-badge.png"
// import downloadIosApp from "../assets/img/download-apple.png"
import shieldBg from "../assets/img/shield-bg.png"

import Contact from "components/Contact/Contact";
const metricListing = [
  {
    icon: require("assets/img/icons/users.svg"),
    metricTitle: "Total users",
    count: 2000,
    plus: "+",
  },
  {
    icon: require("assets/img/icons/calendar.svg"),
    metricTitle: "Monthly Transactions ",
    count: 4505,
    plus: "+",
  },
  {
    icon: require("assets/img/icons/money.svg"),
    metricTitle: "Total Transaction Amount",
    count: 84000,
    plus: "+",
    currency: "$",
  },
  // {
  //   icon: require("assets/img/icons/flag.svg"),
  //   metricTitle: "Countries",
  //   count: 4,
  //   plus: "",
  // },
];


function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <IndexNavbar/>
      <IndexHeader/>
      <div className="main">

        <Container id={'about'} className="my-5">
          <hr/>
        </Container>
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="9">
                <div className="d-flex align-items-center justify-content-center mb-4">
                  <h2 className="title text-nowrap m-0">About Us</h2>
                </div>
                <h5 className="description">
                Our objective is to find and promote charitable organizations and causes that encourage the improvement of social and economic conditions in Africa, primarily through education.
                Our goal is to use education as an agent of change, improve health and livelihoods of disadvantaged communities, contribute to social stability and drive long-term economic growth across Africa
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <Container id={'team'} className="my-5">
          <hr/>
        </Container>
        <Contact/>
      </div>
      <DemoFooter/>
    </>
  );
}

export default Index;
