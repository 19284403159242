import React, { useState } from "react";
// import { db } from "../../firebase/firebase.js";

// reactstrap components
import {
    Button,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Card,
    CardBody,
    Row,
    Col, UncontrolledTooltip,
  } from "reactstrap";

  import Fade from 'react-reveal/Fade';
  import CountUp from 'react-countup';

import guineaFlag from "../../assets/img/flags/guinea-flag.png"
import ghanaFlag from "../../assets/img/flags/ghana-flag.png"
import kenyaFlag from "../../assets/img/flags/kenya-flag.png"
import rwandaFlag from "../../assets/img/flags/rwanda-flag.png"
import emailIcon from "../../assets/img/icons/envelope-solid.svg"
import locationIcon from "../../assets/img/icons/location-dot-solid.svg"
import phoneIcon from "../../assets/img/icons/phone-solid.svg"

const metricListing = [
  {
    icon: locationIcon,
    metricTitle: "Address",
    count: "Fremont, California, USA. 94555",
    plus: "",
  },
  {
    icon: emailIcon,
    metricTitle: "Email",
    count: "educateafricafdn@gmail.com",
    plus: "",
  },
  {
    icon: phoneIcon,
    metricTitle: "Phone",
    count: "+1 408 242 2153",
    plus: "",
    currency: "",
  },
  // {
  //   icon: require("assets/img/icons/flag.svg"),
  //   metricTitle: "Countries",
  //   count: 4,
  //   plus: "",
  // },
];

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    // db.collection("contacts")
    //   .add({
    //     name: name,
    //     email: email,
    //     subject: subject,
    //     message: message,
    //   })
    //   .then(() => {
    //     setLoader(false);
    //     alert("Your message has been submitted👍");
    //   })
    //   .catch((error) => {
    //     alert(error.message);
    //     setLoader(false);
    //   });

    // setName("");
    // setEmail("");
    // setSubject("");
    // setMessage("");
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="section section-contact" id={'contact'}>
          {/* <Container className="flags-block">
            <img className="kenya-flag" src={kenyaFlag} alt={'kenya-flag'}/>
            <img className="guinea-flag" src={guineaFlag} alt={'guinea-flag'}/>
            <img className="ghana-flag" src={ghanaFlag} alt={'ghana-flag'}/>
            <img className="rwanda-flag" src={rwandaFlag} alt={'rwanda-flag'}/>
          </Container> */}
         
          <Container className="contact-block">
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Do you want to Donate, Please Contact us!</h2>
              
                {/* <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required/>
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Subject</label>
                      <Input placeholder="Subject" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} required/>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Tell us your thoughts and feelings..."
                    type="textarea"
                    rows="4"
                    required
                    value={message} onChange={(e) => setMessage(e.target.value)}
                  />
                  <Row>
                    <Col className="ml-auto mr-auto text-center" md="5">
                      <Button className="btn-fill btn-round" color="primary" size="lg" style={{ background: loader ? "#ccc" : "#10AC84" }}>
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form> */}
              </Col>
            </Row>
            <div className="section section-metrics">
          <Container>
            <Row>
              {metricListing.map(item => {
                return (
                    <Col lg="4" key={item.id}>
                      <Card>
                        <img className="metrics-icon" src={item.icon} alt={item.icon}/>
                        <CardBody>
                          <p>{item.metricTitle}</p>
                          <p className="mt-3 font-weight-bold">
                            {item.count}
                            <span>{item.plus}</span></p>
                        </CardBody>
                      </Card>
                    </Col>
                );
              })}
            </Row>
          </Container>
        </div>
            {/* <Row className="justify-content-md-center sharing-area text-center">
              <Col className="text-center" lg="8" md="12">
                <h3>Please follow us on our socials</h3>
              </Col>
              <Col className="text-center" lg="8" md="12">
                <Button
                    className="twitter-sharrre btn-round"
                    color="primary"
                    href="https://twitter.com/MuduPay"
                    target="_blank"
                    id="tooltip3373767"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip3373767">
                  Tweet!
                </UncontrolledTooltip>
                <Button
                    className="sharrre btn-round ml-2"
                    color="primary"
                    href="https://www.instagram.com/mudupay/"
                    target="_blank"
                    id="tooltip864353654"
                >
                  <i className="fa fa-instagram" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip864353654">
                  Follow on Instagram
                </UncontrolledTooltip>
                <Button
                    className="linkedin-sharrre btn-round  ml-2"
                    color="primary"
                    href="https://www.linkedin.com/company/mudupay/"
                    target="_blank"
                    id="tooltip840791273"
                >
                  <i className="fa fa-linkedin" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip840791273">
                  Join us on LinkedIn
                </UncontrolledTooltip>
                <Button
                    className="facebook-sharrre btn-round ml-2"
                    color="primary"
                    href="https://www.facebook.com/mudupay.gh"
                    target="_blank"
                    id="tooltip68961360"
                >
                  <i className="fa fa-facebook-square" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip68961360">
                  Share!
                </UncontrolledTooltip>
              </Col>
            </Row> */}

          </Container>
        </div>
    </form>
  );
};

export default Contact;
