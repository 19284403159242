/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import PrivacyNavbar from "components/Navbars/PrivacyNavbar.js";
import PrivacyHeader from "../components/Headers/PrivacyHeader";
import DemoFooter from "components/Footers/DemoFooter.js";


function Privacy() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <PrivacyNavbar/>
      <PrivacyHeader/>
      <div className="main">
        <div className="section">
          <Container>
            <p>Last updated Dec 17, 2020</p>
            <p>
              <span className="font-weight-bold">MuduPay</span> (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Application”). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.
            </p>
            <p>
              We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.
            </p>

            <p>
              This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party.
            </p>



            <h4 className="font-weight-bold">COLLECTION OF YOUR INFORMATION</h4>
            <p>
              We may collect information about you in a variety of ways: <br/>
              We collect your data when:
            </p>
            <ul>
              <li>You create your user account</li>
              <li>You browse on our site or application and consult our products and services</li>
              <li>You use one of our services (registration, questions and answers, download, request for quotation, quiz...)</li>
              <li>You use one of our contact forms</li>
            </ul>

            <h5 className="font-weight-bold">Collection of information</h5>

            <p className="font-weight-bold">Personal data</p>
            <p>
              Personal data is the information that can be used to identify you. The data we collect is used to significantly improve our services as well as the satisfaction of our users. <br/>
              This data is collected as follows:
            </p>
            <ul>
              <li>The information you provide to us : to access our services, either by creating an account, you provide us personal information such as: contact details (your name, e-mail address, address, telephone number, city, PIN, country...) and also financial data... which are necessary for the execution of the services offered by the application and registered with your account.</li>
              <li>The information we collect from a variety of sources : we collect information about the services you use and how you use them. One of our collection sources is the use of cookies and other technologies that record Data about the use of our application, websites that set up our Services, and about the use of our Services in general. It's about that:
                <ul>
                  <li>Data relating to the device used and the browser: such as IP address, device type, operating system type and type of Internet browser, screen resolution, operating system name and version, language, modules...</li>
                  <li>Location data: When using our services, we may collect and process data relating to your exact location.</li>
                  <li>Cookies and similar technologies: when you visit our website and use our services, we collect and process data relating to your browsing such as time spent on the Services, pages visited, language preferences, and other anonymous traffic data. A cookie policy describing in detail how to use cookies and other similar technologies has been implemented.</li>
                </ul>
              </li>
            </ul>
            <p>All this data will be used on the one hand to adapt our contents and services to your needs, and on the other hand to compile statistics on the use of our services in order to make them more relevant.</p>

            <p className="font-weight-bold">Financial data</p>

            <p>
              All secret code to authorize mobile money services is requested for only fulfilling the transaction. We store no mobile wallet secret in our application and any of our services. <br/>
              To complete mobile money transactions, our external USSD service provider Hover will ask to enter secret PIN. You are encouraged to review their privacy policy and contact them directly for
            </p>

            <p className="font-weight-bold">Geo-Location Information</p>
            <p>
              We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.
            </p>

            <p className="font-weight-bold">Mobile Device Access</p>
            <p>
              We may request access or permission to certain features from your mobile device, including your mobile device’s camera, keyboard, contact, message, storage, internet, call. If you wish to change our access or permissions, you may do so in your device’s settings.
            </p>

            <p className="font-weight-bold">Mobile Device Data</p>
            <p>
              Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.
            </p>

            <p className="font-weight-bold">Push Notifications</p>
            <p>
              We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.
            </p>

            <p className="font-weight-bold">Third-Party Data</p>
            <p>
              Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Application permission to access this information.
            </p>

            <p className="font-weight-bold">Data From Contests, Giveaways, and Surveys</p>
            <p>
              Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
            </p>



            <h4 className="font-weight-bold">USE OF YOUR INFORMATION</h4>
            <p>
              Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to:
            </p>
            <ol>
              <li>Administer sweepstakes, promotions, and contests.</li>
              <li>Assist law enforcement and respond to subpoena.</li>
              <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
              <li>Create and manage your account.</li>
              <li>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Application to you.</li>
              <li>Email you regarding your account or order.</li>
              <li>Enable user-to-user communications.</li>
              <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Application.</li>
              <li>Generate a personal profile about you to make future visits to the Application more personalized.</li>
              <li>Increase the efficiency and operation of the Application.</li>
              <li>Monitor and analyze usage and trends to improve your experience with the Application.</li>
              <li>Notify you of updates to the Application.</li>
              <li>Offer new products, services, mobile applications, and/or recommendations to you.</li>
              <li>Perform other business activities as needed.</li>
              <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
              <li>Process payments and refunds.</li>
              <li>Request feedback and contact you about your use of the Application.</li>
              <li>Resolve disputes and troubleshoot problems.</li>
              <li>Respond to product and customer service requests.</li>
              <li>Send you a newsletter.</li>
              <li>Solicit support for the Application.</li>
            </ol>


            <h4 className="font-weight-bold">DISCLOSURE OF YOUR INFORMATION</h4>
            <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
            <p className="font-weight-bold">By Law or to Protect Rights</p>
            <p>
              If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.
            </p>

            <p className="font-weight-bold">Third-Party Service Providers</p>
            <p>We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</p>

            <p className="font-weight-bold">Marketing Communications</p>
            <p>With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</p>

            <p className="font-weight-bold">Interactions with Other Users</p>
            <p>If you interact with other users of the Application, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs.</p>

            <p className="font-weight-bold">Online Postings</p>
            <p>When you post comments, contributions or other content to the Applications, your posts may be viewed by all users and may be publicly distributed outside the Application in perpetuity</p>

            <p className="font-weight-bold">Affiliates</p>
            <p>We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</p>

            <p className="font-weight-bold">Business Partners</p>
            <p>We may share your information with our business partners to offer you certain products, services or promotions.</p>

            <p className="font-weight-bold">Social Media Contacts</p>
            <p>If you connect to the Application through a social network, your contacts on the social network will see your name, profile photo, and descriptions of your activity.</p>

            <p className="font-weight-bold">Other Third Parties</p>
            <p>We may share your information with  investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.</p>

            <p className="font-weight-bold">Sale or Bankruptcy</p>
            <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.</p>
            <p>We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.</p>



            <h4 className="font-weight-bold">TRACKING TECHNOLOGIES</h4>

            <p className="font-weight-bold">Cookies and Web Beacons</p>
            <p>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the Application and improve your experience. When you access the Application, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Application. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.</p>

            <p className="font-weight-bold">Website Analytics</p>
            <p>We may also partner with selected third-party vendors, such as Google Analytics, Firebase analytics to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors.
              </p>
            <p>
              You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
            </p>



            <h4 className="font-weight-bold">THIRD-PARTY WEBSITES</h4>
            <p>The Application may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Application, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Application.</p>

            <h4 className="font-weight-bold">SECURITY OF YOUR INFORMATION</h4>
            <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>

            <h4 className="font-weight-bold">POLICY FOR CHILDREN</h4>
            <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>

            <h4 className="font-weight-bold">OPTIONS REGARDING YOUR INFORMATION</h4>
            <p className="font-weight-bold">Account Information</p>
            <p>
              You may at any time review or change the information in your account by:
              <ul>
                <li>Logging into your account settings and updating your account</li>
              </ul>
            </p>
            <p>
              Also, you can terminate your account by:

              <ul>
                <li>Contacting us at <a href="mailto:souleymanemoudou@gmail.com">souleymanemoudou@gmail.com</a></li>
              </ul>
            </p>

            <p>
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
            </p>

            <p className="font-weight-bold">Emails and Communications</p>
            <p>
              If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:

              <ul>
                <li>Contacting us at <a href="mailto:souleymanemoudou@gmail.com">souleymanemoudou@gmail.com</a></li>
              </ul>
              If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.
            </p>



            <h4 className="font-weight-bold">CALIFORNIA PRIVACY RIGHTS</h4>
            <p>
              California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a registered account with the Application, you have the right to request removal of unwanted data that you publicly post on the Application. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Application, but please be aware that the data may not be completely or comprehensively removed from our systems.
            </p>

            <h4 className="font-weight-bold">CONTACT US</h4>
            <p>
              If you have questions or comments about this Privacy Policy, please contact us at:
              <ul>
                <p>
                  <span className="font-weight-bold">MuduPay</span> <br/>
                  Matoto/Conakry <br/>
                  Conakry <br/>
                  +224 625 23 49 89 <br/>
                  <a href="mailto:souleymanemoudou@gmail.com">souleymanemoudou@gmail.com</a>
                </p>
              </ul>
            </p>
          </Container>
        </div>

      </div>
      <DemoFooter/>
    </>
  );
}

export default Privacy;
