/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";

// core components

// assets
import heroImage from 'assets/img/shield-bg.png'

function PrivacyHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/hero-banner.jpg") + ")",
        }}
        className="page-header page-header-small align-items-center"
        data-parallax={true}
        ref={pageHeader}
      >
        {/*<div className="filter" />*/}
        <Container>
          <Row className="align-items-center">
            <Col lg={{size: 3, offset: 1}} md={{size: 4, offset: 1}} sm={12} className="d-lg-block d-md-block d-sm-none d-none">
              <img className="img-fluid" src={heroImage} alt={'shield'}/>
            </Col>
            <Col lg={7} md={6} sm={12}>
              <div className="motto">
                <h1 className="mt-0">MuduPay Privacy Policy
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}


export default PrivacyHeader;
